export default class Tabs
{
    constructor($el, options = {}) {
        this.$el = $el
        this.options = {
            ...options
        }

        this.init()
    }

    init() {
        this.$$tabs = this.$el.querySelectorAll(`[role='tab']`)
        this.$$tabs.forEach($tab => {
            $tab.addEventListener('click', this.onTabClick.bind(this))
        })
    }

    onTabClick(e) {
        const $tab = e.currentTarget
        const $tabPanel = document.getElementById($tab.getAttribute('aria-controls'))

        this.$el.querySelectorAll(`[role='tab']`).forEach($tab => {
            $tab.setAttribute('aria-selected', false)
        })

        this.$el.querySelectorAll(`[role='tabpanel']`).forEach($tabPanel => {
            $tabPanel.setAttribute('hidden', true)
        })

        $tab.setAttribute('aria-selected', true)
        $tabPanel.removeAttribute('hidden')

        e.preventDefault()
    }
}
