import './bootstrap'

import Equinox from './components/Equinox'
import Tabs from './components/Tabs'
import Zoom from './components/Zoom'

window.app.init([
    { name: 'equinox', obj: Equinox },
    { name: 'tabs', obj: Tabs },
    { name: 'zoom', obj: Zoom },
])