'use strict'

export default class Zoom
{
    constructor($el, options = {}) {
        this.$el = $el
        this.options = {
            animDuration: 300,
            animEasing: 'ease',
            classNameContainer: 'c-zoom__container',
            classNameOverlay: 'c-zoom__overlay',
            classNameTarget: 'c-zoom__target',
            ...options
        }

        this.init()
        this.initListeners()
    }

    init() {
        switch (this.$el.tagName) {          
            case 'IMG':
                this.$img = this.$el
                break

            default:
                this.$img = this.$el.querySelector('img')
        }

        this.$container = null
        this.$overlay = null
        this.animImage = null
        this.animOptions = {
            duration: this.options.animDuration,
            easing: this.options.animEasing,
            fill: 'both'
        }
        this.animOverlay = null
        this.isLoaded = false
        this.zoomDirection = null
        this.zoomOutHandler = this.zoomOut.bind(this)
        this.update()
    }

    initListeners() {
        if (this.$img) {
            if (this.$img.complete) {
                this.isLoaded = true
                this.update()
            } else {
                this.$img.addEventListener('load', this.onImageLoad.bind(this), false)
            }
        }

        this.$el.addEventListener('click', this.onClick.bind(this))
    }

    onAnimFinish() {
        if (this.zoomDirection === 'out') {
            this.$container.remove()
            this.$img.style.visibility = 'visible'
            this.$overlay.remove()
        }
    }

    onClick(e) {
        e.preventDefault()

        if (this.isLoaded) {
            this.zoomIn()
        }
    }

    onImageLoad() {
        this.isLoaded = true
        this.update()
    }

    update() {
        this.height = this.$el.offsetHeight
        this.width = this.$el.offsetWidth
    }

    zoomIn() {
        this.zoomDirection = 'in'

        const rect = this.$img.getBoundingClientRect()

        // Render zoomed image to get dimensions
        const $img = document.createElement('img')

        $img.addEventListener('load', () => {
            const $target = document.createElement('div')
            $target.classList.add(this.options.classNameTarget)
            $target.appendChild($img)
            document.body.appendChild($target)
    
            const rectTarget = $img.getBoundingClientRect()
    
            // Remove image once dimensions are obtained
            $target.remove()
    
            // Create overlay
            this.$overlay = document.createElement('div')
            this.$overlay.classList.add(this.options.classNameOverlay)
            document.body.appendChild(this.$overlay)
    
            // Create container where we will zoom from/to
            this.$container = document.createElement('div')
            this.$container.classList.add(this.options.classNameContainer)
            this.$container.appendChild($img)
            this.$container.style.height = rect.height + 'px'
            this.$container.style.left = rect.x + 'px'
            this.$container.style.top = (window.scrollY + rect.y) + 'px'
            this.$container.style.width = rect.width + 'px'
            document.body.appendChild(this.$container)
    
            // Hide original image
            this.$img.style.visibility = 'hidden'
    
            // Calculate transform values
            const ratio = rect.width / rectTarget.width
            const scale = rectTarget.width / rect.width
            const moveY = (rectTarget.y - rect.y) * ratio
            const moveX = (rectTarget.x - rect.x) * ratio

            this.animOverlay = this.$overlay.animate([
                { opacity: 0 },
                { opacity: 1 },
            ], this.animOptions)
    
            this.animImage = $img.animate([
                { transform: `scale(1) translate3d(0, 0, 0)` },
                { transform: `scale(${scale})translate3d(${moveX}px, ${moveY}px, 0)` }
            ], this.animOptions)
    
            this.animImage.addEventListener('finish', this.onAnimFinish.bind(this), false)
    
            // Set zoom out handlers
            this.$container.addEventListener('click', this.zoomOutHandler, false)
            this.$overlay.addEventListener('click', this.zoomOutHandler, false)
            window.addEventListener('resize', this.zoomOutHandler, false)
            window.addEventListener('scroll', this.zoomOutHandler, false)
        })

        $img.src= this.$img.src
    }

    zoomOut() {
        this.zoomDirection = 'out'

        // Remove listeners
        this.$container.removeEventListener('click', this.zoomOutHandler, false)
        this.$overlay.removeEventListener('click', this.zoomOutHandler, false)
        window.removeEventListener('resize', this.zoomOutHandler, false)
        window.removeEventListener('scroll', this.zoomOutHandler, false)

        if (this.animOverlay) {
            this.animOverlay.reverse()
        }

        if (this.animImage) {
            this.animImage.reverse()
        }

    }
}
